/*html {
  scroll-behavior: smooth;
}*/

.force-default-font-family {
  font-family: var(--neatin-selected-font), Nunito, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gradient {
  background: linear-gradient(90deg, #48bb78 0%, #9ae6b4 100%);
}
.nav-a:focus + .nav-a {
  display: none !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
  z-index: 10;
}

.camera-setting {
  width: 320px;
}

.tooltip .tooltip-text {
  visibility: hidden;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  z-index: 100;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}

/*.popup {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 30;
}
.popup-inner {
  top: 16%;
}

.popup-filter {
  top: 93.2%;
}*/

.notify {
  margin: -5px -10px !important;
  vertical-align: top;
}

.sticky-category {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 12px 10px 12px 0;
  overflow-x: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sticky-category::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sticky-category {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 767px) {
  .popup-filter {
    top: 95%;
  }

  .sticky-category {
    top: 0;
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #68d391 !important;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 420px) {
  .camera-setting {
    width: 100%;
  }
}

/* ===================== FILE INPUT ===================== */

.image-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.file-dummy {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  text-align: center;
  transition: background 0.3s ease-in-out;
}
.file-dummy .default {
  display: inline-block;
}
.file-dummy:hover {
  background: rgba(255, 255, 255, 0.1);
}

.image-input:focus + .file-dummy {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline: -webkit-focus-ring-color auto 5px;
}

.img-container {
  padding-top: 33.33%;
  min-width: 165px;
  min-height: 165px;
}

.active-menu {
  background-color: #48bb78;
  color: #fff !important;
}

.menu {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* slider range start */
section.range-slider {
  position: relative;
  width: 300px;
  height: auto;
  text-align: center;
}
section.range-slider input[type='range'] {
  pointer-events: none;
  position: absolute;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 14px;
  background: #f1efef;
  box-shadow: inset 0 1px 0 0 #cdc6c6, inset 0 -1px 0 0 #d9d4d4;
  -webkit-box-shadow: inset 0 1px 0 0 #cdc6c6, inset 0 -1px 0 0 #d9d4d4;
  overflow: hidden;
  left: 0;
  top: 30px;
  width: 300px;
  outline: none;
  height: 20px;
  margin-bottom: 20px;
  padding: 0;
  cursor: pointer;
}
section.range-slider input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
  position: relative;
  z-index: 1;
  outline: 0;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 14px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #dad8da),
    color-stop(100%, #413f41)
  );
  /* android <= 2.2 */
  background-image: -webkit-linear-gradient(top, #dad8da 0, #413f41 100%);
  /* older mobile safari and android > 2.2 */
  background-image: linear-gradient(to bottom, #dad8da 0, #413f41 100%);
  /* W3C */
}
section.range-slider input[type='range']::-moz-range-thumb {
  pointer-events: all;
  position: relative;
  z-index: 10;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 14px;
  background-image: linear-gradient(to bottom, #dad8da 0, #413f41 100%);
  /* W3C */
}
section.range-slider input[type='range']::-ms-thumb {
  pointer-events: all;
  position: relative;
  z-index: 10;
  -ms-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 14px;
  border: 0;
  background-image: linear-gradient(to bottom, #dad8da 0, #413f41 100%);
  /* W3C */
}
section.range-slider input[type='range']::-moz-range-track {
  position: relative;
  z-index: -1;
  background-color: rgba(0, 0, 0, 1);
  border: 0;
}
section.range-slider input[type='range']:last-of-type::-moz-range-track {
  -moz-appearance: none;
  background: none transparent;
  border: 0;
}
section.range-slider input[type='range']::-moz-focus-outer {
  border: 0;
}

/* slider range end */

.neatin-filter {
  filter: hue-rotate(-55deg);
}

.force-text-white {
  color: #fff !important;
}

.bg-green-500 {
  background-color: #92d050 !important;
}
.text-green-500 {
  color: #92d050 !important;
}
.text-orange-500 {
  color: #f2aa7a !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/*  React popup animations */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-fade-in-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-body-hidden-content {
  background: transparent;
}

.popup-content {
  width: 100%;
  max-width: 512px;
  /*width: auto;*/
  border: none;
  border-radius: 0.5rem;
  background: transparent;
  padding: 0;
}

/*
* {
  user-select: none;
}
*/
.EZDrawer__container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

/* colors */

.item-selected-bg-green {
  background-color: var(--neatin-category-selected-color) !important;
  color: #fff !important;
}

.item-not-selected-bg {
  background-color: #f1f5f9;
  color: #64748b;
}

.customDrawer .MuiPaper-root {
  background: none !important;
  box-shadow: none !important;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

/* quill */
.ql-editor a {
  /* background-color:#81DAFF; */
  color: var(--neatin-category-selected-color);
  text-decoration: underline;
}

.restaurant-description {
  font-size: 14px;
  color: #64748b;
}

.truncate-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
