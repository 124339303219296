@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: var(--neatin-selected-font), Nunito, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--neatin-selected-font), source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  font-family: var(--neatin-selected-font), Arial, sans-serif;
}

.relt {
  background-color: var(--neatin-selectbackground-font);
  color: var(--neatin-selectbackground-color);
}

.relt2 {
  background-color: var(--neatin-selectbackground-font) !important ;
  color: var(--neatin-selectbackground-color);
}
.customBarkBackground {
  background-color: #424543;
}

.darkDescriptionColor span {
  color: #bab5b5 !important;
}
